import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Refresh from '@material-ui/icons/Refresh';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Confetti from 'react-confetti';
import { useDispatch, useSelector } from 'react-redux';
import domToPdf from 'dom-to-pdf';
import { format } from 'date-fns';
import tweenFunctions from 'tween-functions';
import { Page } from '../../Components/General/Page';
import { Text } from '../../Components/General/Text';
import { IStoreTypes } from '../../store/storeTypes';
import {
  BrancherDispatch,
  SaveDashboardData,
  UtilGetDashboardData,
  UtilGetProgramMeetingSatisfactionFrequencyHealth,
} from '../../store/actions';
import { ActiveUsersHealth } from './ActiveUsersHealth';
import { MeetingHealth } from './MeetingHealth';
import { AllPositions } from '../../consts/ProgramPositionOptions';
import { ActionOutlinedButton } from '../../Components/InputFields/BrancherButton';
import { SearchableDashboardFilters } from './SearchableDashboardFilters';
import { BrancherAccordion } from '../../Components/General/BrancherAccordion';
import { GroupsHealth } from './GroupsHealth';
import { ProgressActivitiesHealth } from './ProgressActivitiesHealth';
import { PairingHealth } from './PairingHealth';
import { ActivityHealth } from './ActivityHealth';
import { HealthCheck } from './HealthCheck';
import { UpdatableProgramInfo } from '../../store/reducers/ProgramReducer';
import { SubscriptionHealth } from './SubscriptionHealth';
import { ProgramSummaryHealth } from './ProgramSummaryHealth';
import { AdminActions } from './AdminActions';
import { BrancherDivider } from '../../Components/General/BrancherDivider';

export enum EChosenProfile {
  ALL = 'all',
  MENTEE = 'MENTEE',
  MENTOR = 'MENTOR',
}

export const ReportingProfileOptions: { label: string; value: EChosenProfile }[] = [
  {
    label: 'All',
    value: EChosenProfile.ALL,
  },
  {
    label: 'Mentees',
    value: EChosenProfile.MENTEE,
  },
  {
    label: 'Mentors',
    value: EChosenProfile.MENTOR,
  },
];

const useStyles = makeStyles({
  dashboardContainer: {
    marginBottom: 50,
  },
  dashboardMain: {
    minHeight: '100%',
  },
});

export const Dashboard = () => {
  const [forPrint, setForPrint] = React.useState<boolean>(false);
  const [hideFilter, setHideFilter] = React.useState<boolean>(false);
  const [gettingMeetingHealth, setGettingMeetingHealth] = React.useState<boolean>(false);
  const [retrievingDashboardData, setRetrievingDashboardData] = React.useState<boolean>(false);
  const isBrancherAdmin = useSelector((state: IStoreTypes) => state.user?.positions)?.includes(
    AllPositions.BRANCHER_ADMIN,
  );
  const programName = useSelector(
    (state: IStoreTypes) => state.program?.[UpdatableProgramInfo.PROGRAM_NAME],
  );
  const congrats = useSelector((state: IStoreTypes) => state.dashboard?.congrats);
  const dashboard = useSelector((state: IStoreTypes) => state.dashboard);
  const dispatch = useDispatch();
  const styles = useStyles();

  React.useEffect(() => {
    if (forPrint) {
      generatePdf();
    }
  }, [forPrint]);

  const generatePdf = () => {
    const element = document.getElementById('dashboard');
    const captureDate = format(new Date(), 'dd/MM/yyyy');

    const options = {
      filename: `${captureDate}-dashboard.pdf`,
      overrideWidth: 2200,
      // excludeTagNames: 'button',
      scale: 0.6,
    };

    return domToPdf(element, options, () => {
      setForPrint(false);
    });
  };

  const setRetrievingInitialDashboardData = (
    refresh: boolean,
    filters: { [x in string]: any } = {},
  ) => {
    setRetrievingDashboardData(true);
    BrancherDispatch(
      dispatch,
      UtilGetDashboardData(refresh, filters, () => {
        setRetrievingDashboardData(false);
      }),
    );
  };

  const setMeetingHealthCheckDashboardData = (
    refresh: boolean,
    filters: { [x in string]: any } = {},
  ) => {
    setGettingMeetingHealth(true);
    BrancherDispatch(
      dispatch,
      UtilGetProgramMeetingSatisfactionFrequencyHealth(refresh, () => {
        setGettingMeetingHealth(false);
        setRetrievingInitialDashboardData(refresh, filters);
      }),
    );
  };

  React.useEffect(() => {
    if (congrats) {
      setTimeout(() => dispatch(SaveDashboardData({ congrats: false })), 7850);
    } else {
      setMeetingHealthCheckDashboardData(false);
    }
  }, []);

  React.useEffect(() => {
    setMeetingHealthCheckDashboardData(false);
  }, [congrats]);

  return (
    <>
      {!hideFilter && (
        <Grid item container>
          <BrancherAccordion
            panels={[
              {
                title:
                  gettingMeetingHealth || retrievingDashboardData
                    ? 'Updating dashboard...'
                    : 'Search dashboard',
                name: 'filters',
                content: (
                  <SearchableDashboardFilters
                    updateSearchCriteria={(f) => setMeetingHealthCheckDashboardData(false, f)}
                    setHideFilter={() => setHideFilter(true)}
                    updatingDashboard={retrievingDashboardData}
                  />
                ),
              },
            ]}
          />
        </Grid>
      )}
      <Page loading={gettingMeetingHealth}>
        {congrats && (
          <Confetti
            tweenFunction={() => tweenFunctions.easeInOutQuad(1, 200, 2000, 1)}
            tweenDuration={4000}
          />
        )}
        <Grid container justifyContent="center" className={styles.dashboardContainer}>
          <Grid container item xs={11} alignItems="center" spacing={2}>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Grid item>
                  <Text variant="xl" marginTop={30} color="purple">
                    {programName} Dashboard
                  </Text>
                </Grid>
              </Grid>
              {isBrancherAdmin && (
                <Box marginTop={4}>
                  <ActionOutlinedButton
                    size="small"
                    onClick={() => setMeetingHealthCheckDashboardData(true)}
                    endIcon={<Refresh />}
                  >
                    Refresh dashboard
                  </ActionOutlinedButton>
                </Box>
              )}
              <Box marginTop={4}>
                <ActionOutlinedButton
                  size="small"
                  loading={forPrint}
                  onClick={() => setForPrint(true)}
                  startIcon={<PictureAsPdf />}
                >
                  Export as PDF
                </ActionOutlinedButton>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={11} container id="dashboard" spacing={2} className={styles.dashboardMain}>
            <Grid item container>
              <HealthCheck />
            </Grid>

            {!forPrint && <BrancherDivider height={2} marginTop={50} marginBottom={50} />}

            {dashboard?.activeUsers && !retrievingDashboardData ? (
              <>
                <Grid item container justifyContent="space-between" spacing={3}>
                  <Grid item xs={6} md={4} lg={3}>
                    <ProgramSummaryHealth
                      activeUsers={dashboard?.activeUsers}
                      applicationApproval={dashboard?.applicationApproval}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <PairingHealth activeUsers={dashboard?.activeUsers} />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <SubscriptionHealth activeUsers={dashboard?.activeUsers} />
                  </Grid>
                  <Grid item xs={6} md={4} lg={2}>
                    <AdminActions
                      activeUsers={dashboard?.activeUsers}
                      applicationApproval={dashboard?.applicationApproval}
                    />
                  </Grid>

                  <BrancherDivider height={2} marginTop={50} marginBottom={20} />

                  {dashboard?.progressActivities && (
                    <Grid item container md={12}>
                      <ActivityHealth activities={dashboard?.progressActivities} />
                    </Grid>
                  )}
                </Grid>

                <BrancherDivider height={2} marginTop={50} marginBottom={20} />

                <Grid item container spacing={3}>
                  <MeetingHealth filters={{}} />
                </Grid>

                <Grid item container spacing={3}>
                  <GroupsHealth filters={{}} />
                </Grid>

                <BrancherDivider height={2} marginTop={50} marginBottom={30} />

                <Grid item container spacing={3}>
                  <ProgressActivitiesHealth filters={{}} />
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item xs={6} md={4}>
                    <ActiveUsersHealth activeUsers={dashboard?.activeUsers} />
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item container justifyContent="center">
                <CircularProgress color="secondary" size={64} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Page>
    </>
  );
};
