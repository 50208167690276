import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import capitalize from '@material-ui/core/utils/capitalize';
import { useDispatch } from 'react-redux';
import { format, fromUnixTime } from 'date-fns';
import { Page } from '../../Components/General/Page';
import {
  BrancherDispatch,
  UtilGetAllProgramMentoringRequests,
  UtilUpdateMentoringRequestStatus,
} from '../../store/actions';
import { Text } from '../../Components/General/Text';
import { BrancherSelect } from '../../Components/InputFields/BrancherSelect';
import { EMentoringRequestStatus, IMentoringRequest } from '../../store/reducers/DashboardReducer';
import { BrancherSnackbar } from '../../Components/General/BrancherSnackbar';
import { RouteMaster } from '../../Components/Routing';
import { BrancherLinkText } from '../../Components/General/BrancherLinkText';

const rowHeight = 100;
const rowAmount = 8;
const headerFooterHeight = 120;
const pageHeight = (rowHeight * rowAmount) + headerFooterHeight;

const useStyles = makeStyles({
  dataGrid: {
    width: '100%',
    minWidth: '100%',
    minHeight: `${pageHeight}px`,
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'fit-content !important',
      minHeight: `${rowHeight}px !important`,
      whiteSpace: 'normal',
      paddingTop: 8,
      paddingBottom: 8,
      '& .MuiPaper-root': {
        marginTop: -150,
      },
      '& .MuiPopover-root': {
        zIndex: 300,
        minHeight: '320px !important',
      },
    },
    '& .MuiDataGrid-row': {
      // overflowY: 'scroll',
      // minHeight: '80px !important',
      maxHeight: '100% !important',
    },
  },
});

export const MentoringRequests = () => {
  const [gettingProgramMentoringRequests, setGettingProgramMentoringRequests] =
    React.useState<boolean>(false);
  const [programMentoringRequests, setProgramMentoringRequests] = React.useState<
    IMentoringRequest[]
  >([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const [updatingMentoringRequest, setUpdatingMentoringRequest] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const styles = useStyles();

  const EReadableStatus = {
    [EMentoringRequestStatus.SENT]: 'Pending',
    [EMentoringRequestStatus.ACCEPTED]: 'Accept',
    [EMentoringRequestStatus.DECLINED]: 'Decline',
    [EMentoringRequestStatus.CANCELLED]: 'Cancel',
  };

  const requestStatusOptions: Array<{ label: string; value: EMentoringRequestStatus }> = [
    {
      value: EMentoringRequestStatus.SENT,
      label: EReadableStatus[EMentoringRequestStatus.SENT],
    },
    {
      value: EMentoringRequestStatus.ACCEPTED,
      label: EReadableStatus[EMentoringRequestStatus.ACCEPTED],
    },
    {
      value: EMentoringRequestStatus.DECLINED,
      label: EReadableStatus[EMentoringRequestStatus.DECLINED],
    },
    {
      value: EMentoringRequestStatus.CANCELLED,
      label: EReadableStatus[EMentoringRequestStatus.CANCELLED],
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'menteeName',
      headerName: 'Mentee Name',
      flex: 120,
      sortable: true,
      renderCell: (grid) => (
        <Tooltip title="See their mentoring request history">
          <Box>
            <BrancherLinkText
              underline="always"
              variant="xs"
              fontWeight={500}
              href={`${RouteMaster.user.path}?r=${grid.row.menteeRoleId}`}
            >
              {grid.value}
            </BrancherLinkText>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'mentorName',
      headerName: 'Mentor Name',
      flex: 120,
      sortable: true,
      renderCell: (grid) => (
        <Tooltip title="See their mentoring request history">
          <Box>
            <BrancherLinkText
              underline="always"
              variant="xs"
              fontWeight={500}
              href={`${RouteMaster.user.path}?r=${grid.row.mentorRoleId}`}
            >
              {grid.value}
            </BrancherLinkText>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: 'requestDate',
      headerName: 'Requested Date',
      flex: 120,
      sortable: true,
      renderCell: (grid) => (
        <Text variant="xxs">{format(fromUnixTime(Number(grid.value) / 1000), 'dd/MM/yyyy')}</Text>
      ),
    },
    {
      field: 'requestInitiator',
      headerName: 'Requester',
      flex: 80,
      sortable: true,
      renderCell: (grid) => <Text variant="xxs">{capitalize(grid.value.toString())}</Text>,
    },
    {
      field: 'status',
      headerName: 'Request status',
      flex: 250,
      sortable: true,
      renderCell: (grid) => (
        <BrancherSelect
          value={grid.value as string}
          options={requestStatusOptions}
          disabled={grid.value !== EMentoringRequestStatus.SENT || updatingMentoringRequest}
          fullWidth
          disablePortal={false}
          name="request-status"
          updateValue={(a: EMentoringRequestStatus) =>
            updateMentoringRequestStatus(grid.id.toString(), a)
          }
        />
      ),
    },
  ];

  React.useEffect(() => {
    setGettingProgramMentoringRequests(true);
    BrancherDispatch(
      dispatch,
      UtilGetAllProgramMentoringRequests((MentoringRequests) => {
        setGettingProgramMentoringRequests(false);
        setProgramMentoringRequests(MentoringRequests);
      }),
    );
  }, []);

  const updateMentoringRequestStatus = (requestId: string, status: EMentoringRequestStatus) => {
    if (status !== EMentoringRequestStatus.SENT) {
      setUpdatingMentoringRequest(true);
      BrancherDispatch(
        dispatch,
        UtilUpdateMentoringRequestStatus(requestId, status, (res) => {
          const newMentoringRequests = programMentoringRequests.map((s) => {
            if (s.mentoringRequestId === requestId) {
              return { ...s, status };
            } else {
              return s;
            }
          });
          setUpdatingMentoringRequest(false);
          setSnackbarOpen(true);
          setProgramMentoringRequests(newMentoringRequests);
        }),
      );
    }
  };

  const modifiedMentoringRequestData = programMentoringRequests?.map((u) => ({
    ...u,
    id: u.mentoringRequestId,
  }));

  return (
    <Page loading={gettingProgramMentoringRequests}>
      <BrancherSnackbar
        controlClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
        message="Request status successfully updated"
      />
      <Grid container justifyContent="center">
        <Grid container item xs={12}>
          <Box className={styles.dataGrid}>
            {programMentoringRequests?.length > 0 ? (
              <DataGrid
                rows={modifiedMentoringRequestData}
                columns={columns}
                pageSize={rowAmount}
                disableSelectionOnClick
              />
            ) : (
              <Grid container item alignItems="center" justifyContent="center">
                <Text variant="md" fontWeight={500} color="purple" marginTop={150}>
                  There are no "Pending" mentoring requests.
                </Text>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};
