import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { IBaseHealthData } from '../../store/reducers/DashboardReducer';
import { EChosenProfile, ReportingProfileOptions } from './Dashboard';
import { BrancherGridCard } from '../../Components/General/BrancherGridCard';
import { Text } from '../../Components/General/Text';
import { SegmentedControlGroup } from '../../Components/InputFields/BrancherSegmentedControl';

const useStyles = makeStyles({
  dashboardCard: {
    maxWidth: 400,
    padding: 25,
    minHeight: 'fit-content',
    height: 340,
  },
});

interface ICompletedProfiles {
  completedProfiles: IBaseHealthData;
}

export const CompletedProfilesHealth: React.FC<ICompletedProfiles> = (props) => {
  const [chosenProfile, setChosenProfile] = React.useState<EChosenProfile>(EChosenProfile.ALL);
  const { completedProfiles } = props;
  const styles = useStyles({});

  let completed = 0;
  let percentage = 0;
  let totalUserAmt = 0;
  if (chosenProfile === EChosenProfile.ALL) {
    completed = completedProfiles?.all;
    percentage = completedProfiles?.allPercentage;
    totalUserAmt = completedProfiles?.totalAllAmount;
  } else if (chosenProfile === EChosenProfile.MENTEE) {
    completed = completedProfiles?.mentee;
    percentage = completedProfiles?.menteePercentage;
    totalUserAmt = completedProfiles?.totalMenteeAmount;
  } else if (chosenProfile === EChosenProfile.MENTOR) {
    completed = completedProfiles?.mentor;
    percentage = completedProfiles?.mentorPercentage;
    totalUserAmt = completedProfiles?.totalMentorAmount;
  }

  return (
    <Grid container>
      <Grid item xs={11}>
        <SegmentedControlGroup
          value={chosenProfile}
          updateValue={(v: EChosenProfile) => setChosenProfile(v)}
          options={ReportingProfileOptions}
        />
      </Grid>
      <BrancherGridCard
        container
        item
        direction="column"
        spacing={3}
        className={styles.dashboardCard}
        xs={11}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item container justifyContent="center">
          <Text variant="lg" display="inline" color="purple" fontWeight={700}>
            {totalUserAmt}{' '}
          </Text>
          <Text variant="lg" display="inline" color="tertiaryGrey" fontWeight={600}>
            (out of {completed})
          </Text>
        </Grid>
        <Grid item>
          <Text variant="lg" fontWeight={500} align="center">
            Completed{' '}
            {chosenProfile === EChosenProfile.MENTEE
              ? 'Mentee '
              : chosenProfile === EChosenProfile.MENTOR
              ? 'Mentor '
              : ''}
            Profiles
          </Text>
        </Grid>
        <Grid item>
          <Text variant="xs" color="placeholderGrey" align="center">
            {percentage ?? '0'}% of{' '}
            {chosenProfile === EChosenProfile.ALL
              ? 'users'
              : chosenProfile === EChosenProfile.MENTOR
              ? 'mentors'
              : 'mentees'}{' '}
            have completed their profile
          </Text>
        </Grid>
      </BrancherGridCard>
    </Grid>
  );
};
